<template>
  <div>
      <button class="btn btn-orange request-button gtag-rfi-information-button" @click="setProgramId">Request {{ mobile ? 'Info' : 'Information' }}</button>
  </div>
</template>

<script>
export default {

    props: {
        program: {
            required: true,
        },
        mobile: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
        setProgramId() {
            // this.$store.commit('setSelectedProgram', this.program_id)
            this.$root.$emit('show_popup', this.program)
        },
    },

}
</script>

<style>

</style>
