import * as $ from 'jquery';
import Swal from 'sweetalert2';

export default (function () {
    $(document).on('click', "form.prompt button", function(e) {
        var _this = $(this);
        e.preventDefault();
        Swal.fire({
            title:  _this.closest("form").data('title') ?  _this.closest("form").data('title') : 'Are you sure?', 
            text: _this.closest("form").data('message') ? _this.closest("form").data('message') : 'Are you sure to continue ?', 
            type: _this.closest("form").data('type') ? _this.closest("form").data('type') : 'error',
            showCancelButton: true,
            confirmButtonColor: 'null',
            cancelButtonColor: 'null',
            confirmButtonClass: 'btn btn-danger',
            cancelButtonClass: 'btn btn-primary',
            confirmButtonText: 'Yes', 
            cancelButtonText: 'Cancel', 
        }).then(res => {
            if (res.value) {
                _this.closest("form").submit();
            }
        });
    });
}())
