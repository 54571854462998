<template>
  <div>
        <div v-if="!sent">
            <div class="form-group">
                <textarea class="form-control" cols="30" rows="10" v-model="message"></textarea>
            </div>

            <button @click="submitMessage()" class="btn btn-primary">Send</button>
        </div>
        <div v-if="sent">
            <div class="alert alert-success alert-dismissible fade show" role="alert">
                Message sent. We will respond promptly, if needed.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="resetForm()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'OnboardingContactComponent',
    props: {
        userId: {
            type: Number,
            default: null,
            required: false
        },
        program: {
            type: Number,
            default: null,
            required: false
        },
        step: {
            type: String,
            default: 'Unknown',
            required: false
        }
    },
    data() {
        return {
            message: '',
            sent: false,
            sending: false,
        }
    },
    methods: {
        submitMessage: function() {

            this.sending = true
            // console.log(this.message)


            let postData = {
                message: this.message,
                program: this.program,
                user: this.userId,
                step: this.step
            }
            
            axios.post('/api/verify/contact', postData)
            .then(({ data }) => {
                this.sent = true
                this.sending = false
            })
            .catch(function (error) {
                // reject(error.response.data.message)
                this.sending = false
                // console.log(error.response.data)
            })
        },

        resetForm: function() {
            this.sent = false
            this.sending = false
            this.message = ''
        }
    }
}
</script>

<style>

</style>