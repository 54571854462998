<template>
   <li class="dropdown mt-4 mt-md-0 nav-item">

            <b-dropdown right :text="userText" class=""  v-if="isLogged" role="button" id="topdrop" toggle-tag="a" :toggle-class="'nav-link'">
                <b-dropdown-item href="/save/program" class="nav-item" link-class="nav-link">Saved Programs</b-dropdown-item>
                <b-dropdown-item href="/profile/account" class="nav-item" link-class="nav-link">Dashboard</b-dropdown-item>
                <b-dropdown-divider class="divider d-none d-md-block"></b-dropdown-divider>
                <b-dropdown-item href="#" @click.prevent="logout()" class="nav-item" link-class="nav-link">Logout</b-dropdown-item>
            </b-dropdown>

            <!-- <a id="topdrop" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" data-trigger="mouseover" aria-haspopup="true" role="button" aria-expanded="false" v-if="isLogged">{{ user.user.email }}</a>
            <ul class="dropdown-menu" role="menu" aria-labelledby="topdrop" v-if="isLogged">

                <li class="nav-item">
                    <a href="/admin/dashboard" class="nav-link">Dashboard</a>
                </li>

                <li role="separator" class="divider"></li>

                <li class="nav-item">

                     <a class="nav-link" @click.prevent="logout()">Logout</a>
                </li>

            </ul> -->


           <a href="#" class="nav-link"  @click.prevent="toggle()" v-if="!isLogged" >Sign in</a>
           <login-form ref="login" :modalId="'navLogin'"></login-form>
           <!-- <component v-bind:is="'login-form'" :show="showModal"></component> -->

    </li>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginComponent from './LoginComponent.vue'

export default {
    name: 'NavLoginComponent',
    components: {
        'login-form' : LoginComponent
    },
    data() {
        return {
            showModal: false
        }
    },
    mounted() {
        // console.log(this.user)
    },
    computed: {
        ...mapGetters([
        'isLogged'
        ]),
        user : function() {
            return this.$store.state.user
        },
        userText: function() {
            // console.log('USERTEXT', this.user);
            let user = this.user.user || this.user;
            return user ? user.email.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'') : ''
        }
    },
    methods: {
        toggle: function() {
            this.$refs.login.showModal()
        },
        logout: function() {
            this.$store.dispatch('logout')
            .then(() => {
                // this.$router.push({ name: 'About' })
                // console.log(response)
                // console.log(this.user)

            })
            .catch(err => {
                console.log(err)
            })
        }
    }
}
</script>

<style>

</style>
