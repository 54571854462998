<template>
    <div>
        <b-modal :id="modalId" title="" hide-footer hide-header no-close-on-esc close-on-backdrop :modal-class="'auth-modal'" >
            <button class="close-btn"  @click="closeForm()">
                ×
            </button>
            <div class="auth-layout ">
                <div class="auth-outer-container">
                    <div class="auth-box" id="login-box" v-if="mode == 'login'">
                        <div class="auth-box-inner">
                            <div class="auth-box-head">
                                <h4>{{loginText}}</h4>
                            </div>
                            <div class="auth-box-body">
                                <form  @submit.prevent="login" id="login-form">


                                    <div class="input-grp">
                                        <div class="input-container email">
                                            <input class="input" name="email" id="email-login" placeholder="Email address" type="text" aria-label="Email address" value="" required autocomplete="email" autofocus v-model="email">
                                        </div>
                                        <div class="input-container password">
                                            <input class="input" name="password" id="password-login" placeholder="Password" type="password" aria-label="Password" required autocomplete="current-password" aria-autocomplete="list" v-model="password">
                                        </div>
                                    </div>
                                    <!-- @if ($errors->any()) -->
                                    <div id="error-message" class="error-message" v-if="Object.keys(this.errors).length > 0">
                                        <span class="d-block" v-for="(error, index) in errors.email" :key="index">
                                            {{error}}
                                        </span>
                                        <span class="d-block" v-for="(error, index) in errors.password" :key="index">
                                            {{error}}
                                        </span>
                                    </div>
                                    <!-- @endif -->

                                    <div class="forgot-password">
                                        <!-- @if (Route::has('password.request')) -->
                                            <a class="" href="/password/reset">
                                                Forgot Password?
                                            </a>
                                        <!-- @endif -->
                                    </div>

                                    <div class="submit-container">
                                        <button type="submit" class="btn btn-primary btn-block">
                                            Log In
                                        </button>
                                    </div>

                                    <div class="vertical-separator">
                                        <span>OR</span>
                                    </div>

                                    <div class="social-providers">
                                        <a href="/social/login/google" id="btn-google" class="btn">
                                            <span class="icon" data-provider="google"></span>
                                            Log In with Google
                                        </a>
                                    </div>

                                    <div class="social-providers">
                                        <a href="/social/login/facebook" id="btn-google" class="btn">
                                            <span class="icon" data-provider="facebook"></span>
                                            Log In with Facebook
                                        </a>
                                    </div>

                                    <div class="social-providers">
                                        <a href="/social/login/linkedin" id="btn-google" class="btn">
                                            <span class="icon" data-provider="linkedin"></span>
                                            Log In with Linkedin
                                        </a>
                                    </div>
                                </form>
                            </div>
                            <div class="auth-box-footer">
                                <span class="signup-toggle-text">Don't have an account?</span> <a id="signup-toggle" href="javascript:void(0)" @click.prevent="switchMode('signup')">Sign Up</a><br><br>
                            By signing into your account, you agree to CJB Network's <a href="/terms" target="_blank">Terms of Service</a> and consent to our <a href="/privacy" target="_blank">Privacy Policy</a>.
                            </div>
                        </div>
                    </div>
                    <div class="auth-box" id="signup-box" v-if="mode == 'signup'">
                        <div class="auth-box-inner">
                            <div class="auth-box-head">
                                <h4>{{signupText}}</h4>
                            </div>
                            <div class="auth-box-body">
                                <form @submit.prevent="signup" >


                                    <div class="input-grp">
                                        <div class="input-container email">
                                            <input class="input" name="email"  placeholder="Email address" type="text" aria-label="Email address" value="" required autocomplete="email" autofocus v-model="email">
                                        </div>
                                        <div class="input-container password">
                                            <input class="input" name="password"  placeholder="Password" type="password" aria-label="Password" required autocomplete="new-password" aria-autocomplete="list" v-model="password">
                                        </div>
                                        <div class="input-container password-confirm">
                                            <input class="input" name="password_confirmation" placeholder="Confirm Password" type="password" aria-label="Password" required autocomplete="new-password" aria-autocomplete="list" v-model="passwordConfirm">
                                        </div>
                                    </div>


                                    <div id="error-message" class="error-message" v-if="Object.keys(this.errors).length > 0">
                                        <span v-for="(error,index) in errors.email" :key="index">{{error}}</span>
                                        <span v-for="(error,index) in errors.password" :key="index">{{error}}</span>
                                    </div>


                                    <div class="submit-container">
                                        <button type="submit" class="btn btn-primary btn-block">
                                            Sign Up
                                        </button>
                                    </div>

                                    <div class="vertical-separator">
                                        <span>OR</span>
                                    </div>

                                    <div class="social-providers">
                                        <a href="/social/login/google" id="btn-google" class="btn">
                                            <span class="icon" data-provider="google"></span>
                                            Sign Up with Google
                                        </a>
                                    </div>

                                    <div class="social-providers">
                                        <a href="/social/login/facebook" id="btn-google" class="btn">
                                            <span class="icon" data-provider="facebook"></span>
                                            Sign Up with Facebook
                                        </a>
                                    </div>

                                    <div class="social-providers">
                                        <a href="/social/login/linkedin" id="btn-google" class="btn">
                                            <span class="icon" data-provider="linkedin"></span>
                                            Sign Up with Linkedin
                                        </a>
                                    </div>
                                </form>
                            </div>
                            <div class="auth-box-footer">
                                <span class="login-toggle-text">Already have an account?</span> <a id="login-toggle" @click="switchMode('login')">Log In</a><br><br>
                            By signing up for an account, you agree to CJB Network's <a href="/terms" target="_blank">Terms of Service</a> and consent to our <a href="/privacy" target="_blank">Privacy Policy</a>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>

</template>

<script>
import { BModal, VBModal, ModalPlugin} from 'bootstrap-vue'
import axios from 'axios'

import { mapGetters } from 'vuex'


export default {
    name: "LoginComponent",
    components: {
        BModal,
    },
    data () {
        return {
            email: '',
            password: '',
            passwordConfirm: '',
            errors: {},
            mode: this.initMode,
        }
    },
    props: {
        // autoload: Boolean,
        // show: Boolean,
        modalId: {
            type: String,
            default: 'loginModal'
        },
        initMode: {
            default: 'login',
            type: String
        },
        loginText: {
            type: String,
            default: 'Log In to CJB Network'
        },
        signupText: {
            type: String,
            default: 'Sign up to CJB Network'
        }
    },
    computed: {
        ...mapGetters([
            'isLogged'
        ]),
        user : function() {
            return this.$store.state.user
        }
    },
    directives: {
        'b-modal': VBModal
    },
    methods: {
        showModal: function() {
            this.$bvModal.show(this.modalId)
        },
        closeForm: function() {
            this.$bvModal.hide(this.modalId)
        },
        switchMode: function(mode) {
            this.mode = mode
            this.errors = {}
        },
        login () {
            // console.log(this.user)
            this.$store.dispatch('login', {
                email: this.email,
                password: this.password
            })
            .then(() => {
                // this.$router.push({ name: 'About' })
                // console.log(response)
                this.closeForm()
                this.$emit('LoginSuccess')

            })
            .catch(err => {
                this.errors = err.errors
            })
        },
        signup() {
            this.$store.dispatch('signup', {
                email: this.email,
                password: this.password,
                password_confirmation: this.passwordConfirm
            })
            .then(() => {
                this.closeForm()
                this.$emit('LoginSuccess')
            })
            .catch(err => {
                this.errors = err.errors
            })
        }
    },
    mounted() {
        // if(this.autoload) {
        //     this.show()
        // }
    },


}
</script>



<style scoped>

</style>
