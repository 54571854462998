import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        user: null,
        universities: {},
        next_program_id_to_save: null,
    },

    mutations: {
        setNextProgramIdToSave(state, next_program_id_to_save) {
            state.next_program_id_to_save = next_program_id_to_save
        },

        setUserData(state, userData) {
            state.user = userData
            // localStorage.setItem('user', JSON.stringify(userData))
            // axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
        },

        clearUserData() {
            location.reload()
        },

        setUniversities(state, universitiesData) {
            // console.log('data commited')
            state.universities = universitiesData
        },
    },

    actions: {
        login(context, credentials) {
            return new Promise((resolve, reject) => {
                axios.post('/ajax/login', credentials)
                    .then(({
                        data
                    }) => {
                        context.commit('setUserData', data)
                        resolve(data)
                    })
                    .catch(function(error) {
                        reject(error.response.data)
                    })
            })
        },
        signup(context, credentials) {
            return new Promise((resolve, reject) => {
                axios.get('/sanctum/csrf-cookie').then(response => {
                    // Login...

                    axios.post('/ajax/register', credentials)
                        .then(({
                            data
                        }) => {
                            context.commit('setUserData', data)
                            resolve(data)
                        })
                        .catch(function(error) {

                            reject(error.response.data)
                        })
                })
            })

        },
        logout({
            commit
        }) {
            axios.post('/logout').
            then(({
                data
            }) => {
                commit('clearUserData')
            })

        },
        loadUniversities({
            commit
        }) {
            axios.get('/api/resource/universities').
            then(({
                data
            }) => {
                // console.log('univeristies loaded now committing ...')
                commit('setUniversities', data);
                // console.log('universities list: ' , response)
            })
        },
        checkLogin(context) {
            // console.log('Check LOGIN');
            axios.post('/ajax/check')
                .then(({
                    data
                }) => {
                    context.commit('setUserData', data)
                })
        }

    },

    getters: {
        isLogged: state => !!state.user,
        next_program_id_to_save: state => state.next_program_id_to_save,
    }
})
