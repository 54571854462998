<template>
    <div class="container" :class="{ 'on-blog' : onBlog}">
        <div class="row newsletter-container">
            <div class="col">
                <div class="newsletter-signup-body">
                    <template v-if="onBlog">
                        <div>
                            <h2><img class="d-lg-none d-inline" src="/images/newsletter-icon-sm.png" alt="">STAY UPDATED</h2>
                            <p>
                                <span class="d-none d-sm-block">Join our newsletter to receive <strong>Career&nbsp;Advice, Funding&nbsp;Opportunities, & Program&nbsp;News.</strong></span>
                                <span class="d-sm-none">
                                    Get email updates for: <br> <strong>Grad Funding & Career Advice</strong>
                                </span>
                            </p>
                        </div>
                    </template>
                    <template v-else>
                        <p>Get updates on funding opportunities, career advice, and program news.</p>
                    </template>
                    <form @submit.prevent="signup()" v-if="!success" class="gtag-form-newsletter-submit">
                        <div class="input-group input-group-lg">
                            <input type="email" v-model="email" class="form-control" placeholder="Email" aria-label="Your Email Address" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button class="btn btn-newsletter" type="submit" v-if="!loading" >Sign Up</button>
                                <img src="/images/loading-green.gif" v-else>
                            </div>
                        </div>
                    </form>
                    <div class="newsletter-signup-success" v-else>
                        <p class="m-0">Success! Thank you for subscribing!</p>
                    </div>
                </div>
            </div>
            <div class="col-3 d-lg-block d-none" v-if="onBlog">
                <img src="/images/newsletter-icon.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewsletterSignupComponent',

    props: ['onBlog'],

    data () {
        return {
            email: '',
            loading: false,
            success: false,
        }
    },
    methods: {
        signup: function() {

            var vm = this;
            // console.log('signup button');
            this.loading = true

            let postData = {
                email: this.email,
            }

            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('/api/newsletter/subscribe', postData)
                .then(({ data }) => {
                    // console.log('success subscribtion')
                    // this.successView = true
                    vm.loading = false
                    vm.success = true

                    // this.$cookie.set('nl_sub', '1', { expires: '1Y' });
                })
                .catch(function (error) {
                    // reject(error.response.data.message)
                    // console.log(error.response.data)

                    vm.loading = false
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .newsletter-container {
        margin-top: 1rem;
        padding: 20px 10px;
        box-shadow: 0px 2px 5px rgba(214, 214, 214, 0.5);
        border-radius: 0px;
        margin-bottom : 20px;
        background-color: #fbe8ea;

        .newsletter-signup-body {
            p {
                font-size: 18px;
                font-weight: 500;
            }

            .btn-newsletter {
                color: #fff;
                background-color: #488C04;
                font-weight: 500;
                width: 180px;
            }
        }
    }

    .on-blog {
        .newsletter-container{
            background: url(/images/blog-newsletter.jpg);
            background-size: cover;
            background-repeat: no-repeat;

            @media screen and (max-width: 991px){
                background: #fffeb3;
            }
        }
        .newsletter-signup-body{
            p{
                margin: .5rem 0 1rem!important;
                line-height: 1.6!important;
            }
        }

        .newsletter-signup-body {
            .btn-newsletter {
                @media screen and (max-width: 991px){
                    width: 100px;
                }
            }
        }
    }

    .singlearticle-container .blog-content .article-container .article-body h2{
        font-weight: bold!important;
        margin-bottom: 0!important;
        font-family: "Roboto Black"!important;
        letter-spacing: 2px;

        @media screen and (max-width: 991px){
            font-size: 24px!important;
            margin: 0!important;
        }
    }

    @media screen and (max-width: 991px){
        .singlearticle-container .blog-content .article-container .article-body img{
            margin: 5px 10px 10px 0!important;
            padding: 0;
        }
        .singlearticle-container .blog-content .article-container .article-body p{
            font-size: 14px;
        }
        .newsletter-container {
            .newsletter-signup-body {
                .btn-newsletter {
                    width: auto;
                }
            }
        }
    }

</style>
