<template>
<div>
  <b-modal :id="'shareExpModal'" title="" hide-footer  no-close-on-esc close-on-backdrop size="lg">
    <template v-slot:modal-header>
        <h5 class="modal-title" id="myModalLabel">Share Your Program Experience <small><p class="m-0 mt-1">Help others learn more about your program</p></small></h5>
        <b-button size="sm" variant="outline-danger" @click="close()">
           X
        </b-button>
    </template>


   <div class="row" v-if="successView == false">
       <div class="col-12 col-md-8">
            <ValidationObserver ref="form">
                <form @submit.prevent="shareExperience()">
                    <!-- <input type="hidden" name="program_id" value="{{ $program->id }}"> -->

                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <b>School</b>
                                <ValidationProvider name="School" v-slot="{errors}" rules="required|greaterZero">
                                    <select v-model="selectedUniversity"  @change="loadPrograms(selectedUniversity)" class="form-control">
                                        <option v-bind:value="0">Select Your University</option>
                                        <option v-for="university in universities" v-bind:key="university.id" v-bind:value="university.id">{{ university.name }}</option>
                                    </select>
                                    <div class="input-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                            </div>

                            <div class="form-group">
                                <b>Program</b>
                                <ValidationProvider name="Program" v-slot="{errors}" rules="required|greaterZero">
                                    <select v-model="selectedProgram" class="form-control">
                                        <option v-bind:value="0">Select Your Program</option>
                                        <option v-for="program in programs" v-bind:key="program.id" v-bind:value="program.id">{{ program.name }}</option>
                                    </select>
                                    <div class="input-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <b>Full Name</b>
                                <ValidationProvider name="Full Name" v-slot="{errors}" rules="required">
                                    <input type="text" v-model="name" class="form-control">
                                    <div class="input-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                            </div>



                        </div>

                         <div class="col">
                            <div class="form-group">
                                <b>Graduation Year</b> (optional)
                                <!-- <ValidationProvider name="Year Completed" v-slot="{errors}" rules="pastDate"> -->
                                    <input type="text" v-mask="'####'" v-model="to" class="form-control" placeholder="2013">
                                    <!-- <div class="input-error">{{ errors[0] }}</div> -->
                                <!-- </ValidationProvider> -->

                                <!-- <datepicker :format="'yyyy'" v-model="to" :minimumView="'year'" :maximumView="'year'" :initialView="'year'" :inputClass="'form-control'" :placeholder="'yyyy'"></datepicker> -->
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="hideName" v-model="hideName">
                                <label class="form-check-label" for="hideName">
                                    Don't Display Name
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row"> -->
                        <!-- <div class="col-6">
                            <div class="form-group">
                                Year Enrolled
                                <ValidationProvider name="Year Enrolled" v-slot="{errors}" rules="required|pastDate">
                                    <input type="text" v-mask="'####'" v-model="from" class="form-control" placeholder="2010">
                                    <div class="input-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                                <!-- <datepicker :format="'yyyy'" v-model="from" :minimumView="'year'" :maximumView="'year'" :initialView="'year'" :bootstrapStyling="true" :typeable="true"  :placeholder="'yyyy'"></datepicker> -->
                            <!-- </div>
                        </div>  -->

                    <!-- </div> -->

                    <div class="row mt-3">
                        <div class="col">
                            <div class="form-group">
                                <b>Share Your Experience</b>
                                <ValidationProvider name="Description" v-slot="{errors}" rules="required">

                                    <textarea v-model="description" icols="30" rows="10" class="form-control"></textarea>
                                    <div class="input-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <button type="submit" class="btn btn-success">Submit</button>
                            <p class="mt-3 small">
                                We may include your review in the listing for this program and other content on CJB Network.
                            </p>
                        </div>
                    </div>

                </form>
            </ValidationObserver>
       </div>
       <div class="col-12 col-md-4 shareExperienceSidebar">
           <p><u>You May Want to Share:</u></p>
           <ul>
               <li>Your experience with the program</li>
               <li>Key program details</li>
               <li>Insight or advice for others considering the program</li>
           </ul>
       </div>
   </div>
   <div class="row" v-else>
        <div class="col">
            <p>Thank you for sharing your experience at <b>{{ universities.find(uni => uni.id == selectedUniversity).name }}</b>!<br><br>Your entry should appear on the website within 24 hours.</p>
        </div>
   </div>


</b-modal>
<login-form ref="login" :modalId="'shareLogin'" @LoginSuccess="postLogin()" :login-text="'Log In to submit your review'" :signup-text="'Sign Up to submit your review'"></login-form>
</div>
</template>

<script>

import { mapGetters } from 'vuex'
import { BButton } from 'bootstrap-vue'
import { VueMaskDirective } from 'v-mask'
import LoginComponent from './LoginComponent.vue'



export default {
    name: 'ExperienceModal',
    components: {
        'b-button': BButton,
        'login-form' : LoginComponent
    },
    directives: {
        'mask': VueMaskDirective
    },
    data() {
        return {
            selectedUniversity: 0,
            selectedProgram: 0,
            successView: false,
            programs: null,
             name: null,
            hideName: false,
            from: null,
            to: null,
            description: null,
        }
    },

    props: {
        programId:{
            type: Number,
            default: 0
        },
        universityId: {
            type: Number,
            default: 0
        }
    },

    methods: {
        shareExperience: function() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                // console.log('Experience shared')

                if(this.isLogged) {
                    this.saveExperience();
                } else {
                    this.$refs.login.showModal()
                }
            })
        },
        saveExperience: function() {

            let postData = {
                programId: this.selectedProgram,
                name: this.name,
                anonymous: this.hideName,
                to: this.to,
                description: this.description
            }



            axios.post('/ajax/experience/save', postData)
            .then(({ data }) => {
                // console.log('success experience')
                this.successView = true
            })
            .catch(function (error) {
                // reject(error.response.data.message)
                // console.log(error.response.data)
            })

        },

        postLogin: function() {
            this.saveExperience();
        },
        loadPrograms: function() {
            axios.get('/api/resource/programs/' + this.selectedUniversity).
            then(({data}) => {
                this.programs = data
                console.log('programs list', data)
            })
        },
        close: function() {
             this.$bvModal.hide('shareExpModal')
        },
    },
    computed: {
        ...mapGetters([
        'isLogged'
        ]),
        user : function() {
            return this.$store.state.user
        },
        universities: function () {
            return this.$store.state.universities
        }
    },
    mounted() {
        // console.log('experience modal mounted')


        this.$store.dispatch('loadUniversities')


        if (this.programId != null) {
            // console.log('program id', this.programId)
            this.selectedProgram = this.programId
        }

        if (this.universityId != null) {
            // console.log('university id', this.universityId)
            this.selectedUniversity = this.universityId
            this.loadPrograms()
        }
    }
}
</script>

<style>

</style>
