import { render, staticRenderFns } from "./ReadMoreComponent.vue?vue&type=template&id=fdcd4856&"
import script from "./ReadMoreComponent.vue?vue&type=script&lang=js&"
export * from "./ReadMoreComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports