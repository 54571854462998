<template>
<span v-if="concentrations.length > 0">
    <span class="concentration-value" v-for="(concentration, index) in concentrations" v-bind:key="concentration">
        <span v-if="index < 2">{{ concentration }} <span v-if="index != last">-</span></span>
        <span v-if="index === 2 && !isOpen"><a v-on:click.prevent="isOpen = !isOpen" href="#" class="concentration-more-less">{{ concentrations.length - 2 }} more</a></span>
        <span v-if="index >= 2 && isOpen">{{ concentration }} <span v-if="index != last">-</span></span>
        <span v-if="isOpen && index === last"><span>- </span><a v-on:click.prevent="isOpen = !isOpen" href="#" class="concentration-more-less">less</a></span>
    </span> 
</span>
    
                                                      
</template>

<script>
export default {
    name: 'ConcentrationsComponent',
    data() {
        return {
            isOpen: false,
            last: false
        }
    },
    props: {
        concentrations: {
            type: Array,
            required: true
        }
    },
    mounted() {
        // console.log(this.concentrations)
        this.last = this.concentrations.length - 1
    }
}
</script>

<style>

</style>