<template>
    <div>
        <b-modal :id="modalId" title="" hide-footer hide-header :modal-class="'auth-modal ' + gtag_class" >
            <button class="close-btn"  @click="closeForm()">
                ×
            </button>
            <div class="auth-layout ">
                <div class="auth-outer-container">
                    <div class="auth-box" id="login-box">
                        <div class="auth-box-inner">

                            <div class="alert alert-info" role="alert" v-if="show_success_message">
                                <h4 class="alert-heading">Success!</h4>
                                <p>We are sharing your request for information with {{ university_name }}.</p>
                            </div>

                            <div class="auth-box-body" v-else>
                                <div class="">
                                    <template v-if="from_saved">
                                        <p class="text-center text-orange font-weight-bold">Program Saved</p>
                                        <h4 class="font-weight-bold">Receive More Information?</h4>
                                    </template>

                                    <template v-else>
                                        <h4 class="font-weight-bold">Request Information</h4>
                                    </template>

                                </div>
                                <form  @submit.prevent="sendInfo" id="login-form" :class="gtag_form_class">

                                    <div class="input-grp">
                                        <div class="form-group">
                                            <label class="font-weight-bold m-0" for="first_name">First name</label>
                                            <input class="form-control" name="first_name" id="first_name" type="text" aria-label="First name" value="" required autocomplete="first_name" autofocus v-model="first_name">
                                        </div>
                                        <div class="form-group">
                                            <label class="font-weight-bold m-0" for="last_name">Last name</label>
                                            <input class="form-control" name="last_name" id="last_name" type="text" aria-label="Last name" value="" required autocomplete="last_name" autofocus v-model="last_name">
                                        </div>
                                        <div class="form-group">
                                            <label class="font-weight-bold m-0" for="email">Email address</label>
                                            <input class="form-control" name="email" id="email" type="text" aria-label="Email address" value="" required autocomplete="email" autofocus v-model="email">
                                        </div>
                                        <div class="form-group">
                                            <label class="font-weight-bold m-0" for="phone">Phone</label>
                                            <input class="form-control" name="phone" id="phone" type="text" aria-label="Phone" value="" required autocomplete="phone" autofocus v-model="phone">
                                        </div>
                                        <div class="mb-5">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input class="form-check-input" type="checkbox" required>
                                                    By clicking the checkbox, I consent to representatives of {{ university_name }} contacting me about the {{ program_name }} program via email or phone. Consent not required for enrollment.
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="">
                                        <template v-if="show_success_message">
                                            <button type="submit" class="btn btn-success btn-block btn-lg">
                                                Submit
                                            </button>
                                        </template>
                                        <template v-else>
                                            <div class="row">
                                                <div class="col">
                                                    <button type="submit" class="btn btn-success btn-block btn-lg">
                                                        Yes
                                                    </button>
                                                </div>
                                                <div class="col">
                                                    <button type="button" @click="closeForm()" class="btn btn-block btn-lg btn-secondary mt-0">
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>

</template>

<script>
import { BModal } from 'bootstrap-vue'
import axios from 'axios'

export default {
    name: "RequestInformationPopup",

    components: {
        BModal,
    },

    props: {
        modalId: {
            type: String,
            default: 'request-information'
        },
    },

    data () {
        return {
            from_saved: false,
            first_name: '',
            last_name : '',
            email     : '',
            phone     : '',
            program_id: 0,
            program_name: '',
            university_name: '',
            show_success_message: false,
        }
    },

    methods: {
        closeForm: function() {
            this.$bvModal.hide(this.modalId)
        },
        sendInfo () {
            axios.post('/ajax/information-requests', {
                first_name: this.first_name,
                last_name : this.last_name,
                email     : this.email,
                phone     : this.phone,
                program_id: this.program_id,
            }).then(({data}) => {
                this.show_success_message = true
                // setTimeout(() => {
                    // this.$bvModal.hide('request-information')
                    // console.log(this.$bvModal)
                    // this.show_success_message = false
                    // this.noCloseOnBackdrop = true
                // }, 6000)
            })
        }
    },

    computed: {
        loggedUser() {
            return this.$store.state.user
        },
        gtag_class() {
            return this.from_saved ? 'gtag-request-information-save' : 'gtag-request-information'
        },
        gtag_form_class() {
            return this.from_saved ? 'gtag-form-rfi-submit-save' : 'gtag-form-rfi-submit'
        },
    },

    mounted() {
        this.$root.$on('show_popup', (program, from_saved) => {
            this.from_saved = from_saved || false // Check where the popup was fired

            this.first_name = this.loggedUser ? this.loggedUser.first_name : '',
            this.last_name  = this.loggedUser ? this.loggedUser.last_name : '',
            this.email      = this.loggedUser ? this.loggedUser.email : '',
            this.phone      = this.loggedUser ? this.loggedUser.phone : '',

            this.program_id      = program.id
            this.program_name    = program.name
            this.university_name = program.university_name || program.university.name
            this.$bvModal.show('request-information')
        })
    },


}
</script>



<style>

</style>
