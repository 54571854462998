
// import './rangeslider'
// import './tagify'
import './sweetalert2'
import './programform'

// import 'bootstrap.native'




