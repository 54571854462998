<template>
<div>
    <button class="btn btn-primary share-user-experience-button" @click="loadShareExpModal()">{{buttonText}}</button>
</div>
     
</template>

<script>



export default {
    name: 'ShareExperienceComponent',
    data() {
        return {
            
        }
       
    },
    props: {
        buttonText: {
            type: String,
            default: 'Share Your Experience'
        }
    },
    
    methods: {
        loadShareExpModal: function () {
            // console.log('click on button')
            this.$bvModal.show('shareExpModal')
            
        },
    }
}
</script>

<style>

</style>