import Vue from 'vue';
window.Vue = Vue;



import VueImageLightboxCarousel from 'vue-image-lightbox-carousel';
Vue.component('vue-image-lightbox-carousel', VueImageLightboxCarousel);

import { store } from './store/index'


import {ModalPlugin} from 'bootstrap-vue'
Vue.use(ModalPlugin)

import { DropdownPlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)

// import Buefy from 'buefy'
// import { Datepicker, Field } from 'buefy'
// import 'buefy/dist/buefy.css'

// Vue.use(Datepicker)
// Vue.use(Field)

import { ValidationProvider, ValidationObserver,  extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
// Add a rule.
// extend('required', {
//     validate (value) {
//       return {
//         required: true,
//         valid: ['', null, undefined].indexOf(value) === -1
//       };
//     },
//     message: '{_field_} is required.',
//     computesRequired: true
// });

extend('required', {
    ...required,
    message: '{_field_} is required.',
});

extend('greaterZero', {
    validate(value) {
        return value > 0;
    },
    message: '{_field_} is required.',
})

extend('pastDate', {
    validate (value) {
        return value <= new Date().getFullYear()
    },
    message: '{_field_} cannot be a future date'
})

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// cookies

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);


window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}


Vue.component('vue-image-lightbox-carousel', VueImageLightboxCarousel);
// Vue.component('login-form', require('./components/LoginComponent.vue').default)
Vue.component('vue-nav', require('./components/NavComponent.vue').default)
Vue.component('vue-nav-login', require('./components/NavLoginComponent.vue').default)
Vue.component('detail-image', require('./components/DetailImageComponent.vue').default)
Vue.component('share-experience', require('./components/ShareExperienceComponent.vue').default)
Vue.component('share-experience-modal', require('./components/ShareExperienceModalComponent.vue').default)
Vue.component('experience-modal', require('./components/ExperienceModal.vue').default)
Vue.component('read-more', require('./components/ReadMoreComponent.vue').default)
Vue.component('save-program', require('./components/SaveProgramComponent.vue').default)
Vue.component('request-information', require('./components/RequestInformation.vue').default)
Vue.component('request-information-popup', require('./components/RequestInformationPopup.vue').default)
Vue.component('newsletter-signup', require('./components/NewsletterSignupComponent.vue').default)
Vue.component('concentration-list', require('./components/ConcentrationsComponent.vue').default)
Vue.component('onboarding-contact', require('./components/OnboardingContactComponent.vue').default)

Vue.component('kustomer', require('./components/Kustomer/Kustomer.vue').default)
const app = new Vue({
    el: '#app',
    store,

    created () {
        this.$store.dispatch('checkLogin')

        /*
        // console.log('created')\
        const userInfo = localStorage.getItem('user')
        // console.log(userInfo)
        if (userInfo) {

            const userData = JSON.parse(userInfo)
            this.$store.commit('setUserData', userData)

        }
        axios.interceptors.response.use(
            response => response,
            error => {
                // console.log('interceptor error', error)
                if (error.response.status === 401) {
                    this.$store.dispatch('logout')
                }
                return Promise.reject(error)
            }
        )
        */
    }
});

import VueScrollTo from 'vue-scrollto';
window.VueScrollTo = VueScrollTo;

window.Cookies = require('js-cookie');
window._ = require('lodash');

// import SeoMetaDescriptionReactionCompenent from '../js/components/SeoMetaDescriptionReactionCompenent';
// Vue.use(SeoMetaDescriptionReactionCompenent);

// import './bootstrap'

// import 'bootstrap.native'

// window.bsn = require("bootstrap.native");
// custom css non vue

require('./bootstrap-front');


var moreFilters = document.querySelector('#more-filters')
if (moreFilters) {
    moreFilters.addEventListener('change', function (e) {
        moreFiltersSwitcher(e.target)
    })
}



function moreFiltersSwitcher(elem) {
    if (elem.checked) {
        document.querySelectorAll('.js-advanced-filters-container')[0].classList.remove('d-none');
    } else {
        document.querySelectorAll('.js-advanced-filters-container')[0].classList.add('d-none');
    }
}


if (moreFilters) {
    moreFiltersSwitcher(moreFilters)
}
document.querySelectorAll(".js-clear-btn").forEach(btn => {
    btn.addEventListener('click', function(e) {
        document.querySelectorAll(e.target.dataset.element + ' input[type=checkbox]:checked').forEach( function(elem) {
            elem.checked = false;
        });
    })
})


var preventSubmit = true;

function formSubmit() {
    if (!preventSubmit)
    document.getElementById('filters-form').submit();
}

var filtersForm = document.querySelectorAll('.desktop-filters #filters-form input[type=checkbox]')
if (filtersForm) {
    filtersForm.forEach(function (item) {
        item.addEventListener('change', function(e) {

            preventSubmit = false;
            if (e.target.name !== 'locations[]' && e.target.name !== 'category[]' && e.target.name !== 'more') {
                formSubmit();
            }
        })
    })
}

var jsGpaRange = document.querySelector('.desktop-filters .js-gpa-range')

if (jsGpaRange) {
    jsGpaRange.addEventListener('change', function(e) {
        preventSubmit = false;
        if (e.target.name !== 'locations[]' && e.target.name !== 'category[]' && e.target.name !== 'more') {
            formSubmit();
        }
    })
}


var jsFilters = document.querySelectorAll('.js-filters-form-dropdown')

if (jsFilters) {
    jsFilters.forEach( function(item) {
        item.addEventListener('hidden.bs.dropdown' , formSubmit);
    })
}


if (jsGpaRange) {
    document.querySelector('.js-gpa-range').addEventListener('input', function(e) {

        if (this.value >= 2) {
            document.querySelector('.js-gpa-value').value = parseFloat(this.value).toFixed(2);
            document.querySelector('.js-gpa-value').disabled = false;
        } else {
            document.querySelector('.js-gpa-value').value = '';
            document.querySelector('.js-gpa-value').disabled = true;
        }
    })
}


var sortBy =  document.querySelector('select[name=sortby]');
if (sortBy) {
    sortBy.addEventListener('change', function() {
        console.log(this.value)
        insertParam('sortby', this.value)
    })
}


function insertParam(key, value)
{
    key = encodeURI(key); value = encodeURI(value);

    var kvp = document.location.search.substr(1).split('&');

    var i=kvp.length; var x; while(i--)
    {
        x = kvp[i].split('=');

        if (x[0]==key)
        {
            x[1] = value;
            kvp[i] = x.join('=');
            break;
        }
    }

    if(i<0) {kvp[kvp.length] = [key,value].join('=');}

    //this will reload the page, it's likely better to store this until finished
    document.location.search = kvp.join('&');
}

document.querySelectorAll('.panel-trigger').forEach(function(panel){
    panel.addEventListener('click', function(e) {
        e.preventDefault();

        document.querySelector('body').classList.add('overflow-hidden')
        document.querySelector('.filterbar-mobile-overlay').style.display = 'block';
        console.log(this.dataset)
        let target = this.dataset.target
        console.log(target)
        document.getElementById(target).style.right = '0%';
        document.getElementById(target).classList.remove('d-none');
    })
})

var filtersFormMobile =  document.querySelectorAll('.filters-mobile #filters-form input[type=checkbox]')
if (filtersFormMobile) {
    filtersFormMobile.forEach(function (item) {
        item.addEventListener('change', function(e) {
            preventSubmit = false;
        })
    })
}

var filterbarMobileOverlay = document.querySelector('.filterbar-mobile-overlay');

if(filterbarMobileOverlay) {
    filterbarMobileOverlay.addEventListener('click' , function(e) {

        document.querySelector('body').classList.remove('overflow-hidden')

        document.querySelectorAll('.panel-filters-mobile').forEach(function(panel) {
            panel.style.right = '-80%'
            panel.classList.add('d-none')
        })

        if (!preventSubmit) document.querySelector('#filters-form').submit();

        filterbarMobileOverlay.style.display = 'none';
    })
}


let jsGpaRangeMobile = document.querySelector('.filters-mobile .js-gpa-range')

if (jsGpaRangeMobile) {
    jsGpaRangeMobile.addEventListener('change', function(e) {
        preventSubmit = false;
    })

    jsGpaRangeMobile.addEventListener('input', function(e) {

        if (this.value >= 2) {
            document.querySelector('.js-gpa-value').value = parseFloat(this.value).toFixed(2);
            document.querySelector('.js-gpa-value').disabled = false;
        } else {
            document.querySelector('.js-gpa-value').value = '';
            document.querySelector('.js-gpa-value').disabled = true;
        }
    })
}

// Fix scroll top
var navbar = document.querySelector(".mobile-actions");
window.onscroll = function() {
    if(navbar) {
        stickyBar()
    }
};

var navbar = document.querySelector(".mobile-actions");
if (navbar) {
    var sticky = navbar.offsetTop;
}


function stickyBar() {
    var sticky = navbar.offsetTop;
    if (window.pageYOffset >= sticky + 250) {
        navbar.classList.add("sticky")
    } else {
        navbar.classList.remove("sticky");
    }
}
