<template>
    <div>


    </div>

</template>

<script>

import { BButton } from 'bootstrap-vue'
// import Datepicker from 'vuejs-datepicker';
import { VueMaskDirective } from 'v-mask'
import { mapGetters } from 'vuex'
import LoginComponent from './LoginComponent.vue'



export default {
    name: 'ShareExperienceModal',
    components: {
        'b-button': BButton,
        'login-form' : LoginComponent
    },
    directives: {
        'mask': VueMaskDirective
    },
    data() {
        return {
            name: null,
            hideName: false,
            from: null,
            to: null,
            description: null,
            successView: false,
            // universities: null,
            programs: null,
            selectedUniversity: null,
            selectedProgram: null,
            errors: []
        }
    },
    props: {
        university: String,
        programTitle: String,
        programId: Number,
        universityId: Number
    },
    methods: {
        show: function() {
            this.$bvModal.show('shareExpModal')
            this.$store.dispatch('loadUniversities')


            // axios.get('/api/resource/universities').
            // then(({data}) => {
            //     this.universities = data;
                // console.log('universities list: ' , response)
            // })

            this.loadPrograms(this.selectedUniversity)
        },
        close: function() {
             this.$bvModal.hide('shareExpModal')
        },
        shareExperience: function() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                // console.log('Experience shared')

                if(this.isLogged) {
                    this.saveExperience();
                } else {
                    this.$refs.login.showModal()
                }
            })
        },
        loadPrograms: function(university_id) {
            axios.get('/api/resource/programs/' + university_id).
            then(({data}) => {
                this.programs = data
                // console.log('programs list', data)
            })
        },
        postLogin: function() {
            this.saveExperience();
        },
        saveExperience: function() {

            let postData = {
                programId: this.selectedProgram,
                name: this.name,
                anonymous: this.hideName,
                to: this.to,
                description: this.description
            }



            axios.post('/ajax/experience/save', postData)
            .then(({ data }) => {
                // console.log('success experience')
                this.successView = true
            })
            .catch(function (error) {
                // reject(error.response.data.message)
                // console.log(error.response.data)
            })

        }
    },
    computed: {
        ...mapGetters([
        'isLogged'
        ]),
        user : function() {
            return this.$store.state.user
        },
        universities: function () {
            return this.$store.state.universities
        }
    },
    mounted() {
        if (this.programId != null) {
            // console.log('program id', this.programId)
            this.selectedProgram = this.programId
        }

        if (this.universityId != null) {
            // console.log('university id', this.universityId)
            this.selectedUniversity = this.universityId
        }
    }
}
</script>

<style>
    .shareExperienceSidebar {
        padding-top: 270px;
    }
    .shareExperienceSidebar ul {
       padding-left: 16px;
    }
    .shareExperienceSidebar ul li {
        margin-bottom: 10px;
    }
</style>
