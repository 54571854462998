<template>
   <div class="program-save-container d-flex justify-content-center">
       <a href="#" class="program-save" :class="{ 'gt-program-save-listing' : addText, 'gt-program-save-results' : !addText }" v-if="!programSaved" @click.prevent="saveProgram()" @mouseover="hover = true" @mouseleave="hover = false">
           {{ addText ? 'Save ' : ''}}
           {{ addText && ! mobile ? 'Program ' : ''}}
           <i :class="hover ? 'fas fa-heart' : 'far fa-heart'"></i></a>
       <a href="#" class="program-unsave" v-else @click.prevent="unsaveProgram()" @mouseover="hover = true" @mouseleave="hover = false">{{ addText ? 'Saved ' : ''}}<i :class="!hover ? 'fas fa-heart' : 'far fa-heart'"></i></a>
        <!-- {{ programSaved }} -->

        <keep-alive>
            <login-form
                ref="login"
                :modalId="modalId"
                :login-text="'Log In to save your programs'"
                :signup-text="'Sign Up to save your programs'"
            ></login-form>
        </keep-alive>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LoginComponent from './LoginComponent.vue'

export default {
    name: 'SaveProgramComponent',
    data() {
        return {
            hover: false
        }
    },
    components: {
        'login-form': LoginComponent
    },
    props: {
        program: {
            required: true,
        },
        programSaved: {
            type: Boolean,
            required: true,
        },
        programId: {
            type: Number,
            required: true
        },
        addText: {
            type: Boolean,
            default: false
        },
        mobile: {
            type: Boolean,
            default: false
        }

    },
    computed: {
        ...mapGetters([
            'isLogged', 'next_program_id_to_save'
        ]),
        user: function() {
            return this.$store.state.user
        },
        isSaved: {
            get: function() {
                return this.programSaved
            },
            set: function(newValue) {
                this.programSaved = newValue
            }
        },

        modalId() {
            let str = 'saveProgramLogin'
            str += this.mobile ? '_mobile_' : ''
            str += this.programId
            return str
        },
    },
    methods: {

        saveProgram: function() {
            // axios

            // console.log('save program')

            if (this.isLogged) {

                let postData = {
                    program: this.programId
                }

                axios.post('/ajax/save/program', postData)
                    .then(({ data }) => {
                        this.isSaved = true;
                        if (this.program.is_rfi_save) {
                            this.$root.$emit('show_popup', this.program, true)
                        }
                    })
            } else {
                this.$store.commit('setNextProgramIdToSave', this.programId)
                this.$refs.login.showModal()
            }

        },
        unsaveProgram: function() {
            // axios
            // console.log('unsave program')

            axios.delete('/ajax/save/program/' + this.programId)
                .then(({ data }) => {
                    this.isSaved = false;
                })

        }
    },


    watch: {
        isLogged(val) {
            if(val && this.next_program_id_to_save === this.programId) {
                this.saveProgram()
            }
        }
    }

}
</script>

<style>

</style>
