<template>
    <div class="mobile-offcanvas" id="navbar_main">
        <div class="offcanvas-header">
            <button class="btn btn-close pl-4 pt-0" > &times </button>
        </div>
        <ul class="navbar-nav ml-auto">

            <li class="nav-item d-none">
                <share-experience :button-text="'Review Program'"></share-experience>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/articles">Career Resources</a>
            </li>
            <li class="nav-item d-md-none">
                <share-experience :button-text="'Review Program'"></share-experience>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/about">About Us</a>
            </li>

            <vue-nav-login></vue-nav-login>
        </ul>
    </div>

</template>

<script>
import NavLoginComponent from './NavLoginComponent'

export default {
    name: 'NavComponent',
    components: {
        'vue-nav-login': NavLoginComponent
    }
}
</script>

<style lang="scss" scoped>
    .btn-close{
        font-size: 30px;
    }
    .navbar-nav{
        text-align: center;
    }
</style>
