<template>
  <div>
    <slot name="short" v-if="!isReadMore"></slot>
    <slot name="long" v-else></slot>
    <!-- <p v-html="formattedString"></p> -->
    
    <a href="#" id="readmore" v-show="!isReadMore" v-on:click="triggerReadMore($event, true)">Show More</a>
    <a href="#" id="readmore" v-show="isReadMore" v-on:click="triggerReadMore($event, false)">Show Less</a>
    
  </div>
</template>

<script>
export default {
    name: 'ReadMoreComponent',
    props: {
        maxChars: {
            type: Number,
            default: 100
        }
    },
    data() {
        return {
            isReadMore: false,
            
        };
    },
    // computed: {
    //     formattedString() {
    //         var val_container = this.text;
    //         if (!this.isReadMore && this.text.length > this.maxChars) {
    //             val_container = val_container.substring(0, this.maxChars) + "...";
    //         }
    //         return val_container;
    //     }
    // },
    methods: {
        triggerReadMore(e, b) {
            e.preventDefault();
            this.isReadMore = b;
        }
    },
    // mounted() {
    //     console.log(this.$slots.default)
    //    this.text = this.$slots.default.map(item => item.text).join(' ')

    // }
}
</script>

<style>

</style>