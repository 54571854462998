<template>
    <section class="left-section images-container">
        <div class="header">Program Images</div>
        <div class="body">
            <vue-image-lightbox-carousel 
                :images="images"
                ref="lightbox"
                :show="showLightbox"
                :showCaption="true"
                @close="showLightbox = false"
            ></vue-image-lightbox-carousel>
            
            <div class="image-primary" v-bind:style="{ backgroundImage: 'url(' + images[0].path + ')' }" >
                
            </div>
            <div class="image-overlay" v-on:click="openLightbox">
               
                <span class="image-overlay-text-container"><i class="fas fa-camera"></i><span class="image-overlay-text">All Photos ({{ this.imageCount }})</span></span>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'DetailImageComponent',
    data() {
        return {
            showLightbox: false,
        }
    },
    props: {
        images: Array,
        imageCount : Number
    },
    methods: {
        openLightbox () {
            this.showLightbox = true
            this.$refs.lightbox.showImage(0)
        },
    },
    created() {
        // console.log(this.images)
    }
}
</script>

<style>

</style>